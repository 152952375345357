@import "./fonts/fonts.css";

body {
  margin: 0;
  font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
  overflow: visible !important;
  box-sizing: border-box !important;
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-contained.Mui-disabled {
  border: 2px solid grey;
}

.isHidden-true{
  font-style: italic;  
  color: #a6a6a6;
}

.isHidden-false{
  font-style: normal;
}

.content {
  margin: 10px 0px 0px 0px;
}
